import { Box, Button, Grid, TextField, Typography, Card, CardActionArea, Stack,Pagination  } from '@mui/material'
import React, { useEffect, useState,useRef } from 'react'
import { DocBar, Navbar } from '../component'
import { convertToObject, getData, postData } from '../functions'
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { style } from '@mui/system';
import styles from '../styles';
import { useNavigate } from 'react-router-dom';

const Doctor = ({ setSavedData, doctorData, setDoctorData, facilityData, setFacilityData, procedureInfo, setProcedureInfo }) => {
  const boxRef = useRef();
  const style = styles;

  const [trigger, setTrigger] = useState(false)
  const [addDoctor, setAddDoctor] = useState(false)
  const [docList, setDocList] = useState([])
  const [selected, setSelected] = useState([])
  const [procedureAdd, setProcedureAdd] = useState(false)
  const [filteredDocData, setFilteredDocData] = useState([])
  const [code, setCode] = useState('')
  const [fee, setFee] = useState('')
  const [supply, setSupply] = useState('')
  const [time, setTime] = useState('')
  const [doctorName, setDoctorName] = useState('')

  const [procedureList, setProcedureList] = useState([])
  const [dropDown, setDropDown] = useState(false)

  const [paginatedData, setPaginatedData] = useState([])
  const [pageMax, setPageMax] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * 6;
  const endIndex = startIndex + 6;

  const navigate = useNavigate()

   // to hide dropdown if mousedown somewhere else on the screen
   useEffect(() => {
    function handleClickOutside(event) {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setDropDown(false); 
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); 

  useEffect(() => {
    setPageMax( Math.ceil(filteredDocData.length / 6))
    // pagination
  }, [filteredDocData])

  useEffect(() => {
    setPaginatedData(filteredDocData.slice(startIndex, endIndex));
    // pagination
    
  }, [filteredDocData, currentPage]);


  useEffect(() => {
    if(doctorData.length>0){
      setFilteredDocData(doctorData.filter(item =>  item.doctor === selected))
      // filter the whole doc data list to contain only procedures that was selected by its user
      setCurrentPage(1)
    }
    
  }, [selected]);
  useEffect(() => {
    if (doctorData.length > 0 && typeof (doctorData[0]) !== 'object') {
      setDoctorData(convertToObject(doctorData, ['id','user_id', 'doctor', 'procedure', 'surgeon_fee', 'supplies', 'avg_time']));
    }
    if (typeof (doctorData[0]) === 'object') {
      let temp = [];
      for (let i = 0; i < doctorData.length; i++) {
        if (!temp.includes(doctorData[i].doctor)) {
          temp.push(doctorData[i].doctor);
        }
      }
      setDocList(temp);
    } else {
      setTrigger(!trigger);
    }

   
  }, [doctorData, trigger]);

  useEffect(() => {
    if (procedureInfo.length > 0 && typeof (procedureInfo[0]) !== 'object') {
      setProcedureInfo(convertToObject(procedureInfo, ['id', 'procedure_code', 'procedure_or_surgery', 'implant_cost', 'user_id']));
      
    }
    if(procedureInfo.length > 0 && typeof (procedureInfo[0]) === 'object'){
  
      let temp = []
      for(let i = 0; i<procedureInfo.length; i++){
        if(procedureInfo[i].user_id === facilityData.id){
          temp.push(procedureInfo[i].procedure_code)
        }    
      }
      console.log(temp)
      setProcedureList(temp)
    }
  }, [procedureInfo]);

  useEffect(() => {
    if (facilityData.user === undefined) {
      let user = window.localStorage.getItem('user');
      user = JSON.parse(user);
      if(user !== null){
        setFacilityData(user);
        getData('doctors', setDoctorData, Number(user.id));
        getData('pricing', setProcedureInfo);
        getData('records', setSavedData)
      }else{
        navigate('/')
      }
      
      
    }
  }, []);

  // reset data if user switches from add proc to add proc & doctor
  useEffect(() => {
    setCode('')
    setFee('')
    setSupply('')
    setTime('')

   
  }, [addDoctor, procedureAdd])
  
  useEffect(() => {
    setProcedureAdd(false)
  
  
  }, [addDoctor])
  
  
  function addProcedure() {
    if (fee !== '' && code !== '' && supply !== '' && time !== '') {
      let temp = doctorData
      let id = Number(doctorData[doctorData.length-1].id)+1
      
      let ind = { id:id, user_id: facilityData.id, doctor: selected, procedure: code, surgeon_fee: fee, supplies: supply, avg_time: time  }
      temp.push(ind)

      setDoctorData(temp)
      setProcedureAdd(false)
      postData(ind, 'doctors')
    } else {
      alert('Field(s) not complete')
    }
  }

  function removeDoctor(){
    let assurance = prompt('Type doctors name to confirm')
    if(assurance === selected){
      postData({user_id:facilityData.id, doctor:selected},'doctorRemove')
      
      setSelected('')
    let newDocData = []
    for(let i = 0; i<doctorData.length; i++){
      if(doctorData[i].doctor !== selected && doctorData[i].user_id !== facilityData.id){
        newDocData.push(doctorData[i])
      }
    }
    setDoctorData(newDocData)

    
    let newDocList = []
    for(let i = 0; i<docList.length; i++){
      if(docList[i] !== selected){
        newDocList.push(docList[i])
      }
    }
    setDocList(newDocList)    
    }
  }

  function doctorSubmit() {
    
    let docs = 0
    let docsFound = []
    if(facilityData.account_type === 'demo'){
      for(let i = 0; i<doctorData.length;i++){
        if(doctorData[i].user_id === facilityData.id && !docsFound.includes(doctorData[i].doctor)){
          docsFound.push(doctorData[i].doctor)
          docs += 1
        }
      }
    }
    const filteredProcedureInfo = procedureInfo.filter(item => item.user_id === facilityData.id)
    const result = filteredProcedureInfo.find(item => item.procedure_code === code);
    if (result) {
      if (doctorName !== '' && fee !== '' && code !== '' && supply !== '' && time !== '' && docs < 5) {
        let id = 0
        if(doctorData.length>0){
          id = Number(doctorData[doctorData.length-1].id)
          id += 1
        }
        
        let ind = {id:id,user_id: facilityData.id, doctor: doctorName, procedure: code, surgeon_fee: fee, supplies: supply, avg_time: time }
        if(doctorData.length > 0){
          let temp = doctorData
          temp.push(ind)
          setDoctorData(temp)
          let tempDocList = []
          for (let i = 0; i < temp.length; i++) {
            if (!tempDocList.includes(temp[i].doctor)) {
              tempDocList.push(temp[i].doctor)
            }
          }
          console.log(tempDocList)
          setDocList(tempDocList)

        }else{
          setDoctorData([ind])
          setDocList([doctorName])
        }  
        postData(ind, 'doctors')
        setAddDoctor(false)
      } else if(docs >= 5){
        alert('Maximum Doctor amount reached for this Demo')
      } else{
        alert('Field(s) not complete')
      }
    } else {
     alert("Invalid CPT code")
    }
  }

 
  return (
    <Box sx={style.doctorMainBox}>
    <Navbar  user={facilityData}/>
    <Stack direction='row' sx={style.mainStack}>
      <Box sx={style.boxAfterNavbar}>
          <Stack sx={style.docBarStack}>
          <Typography variant='h3' sx={style.docbarTypo}>Doctors</Typography>
          <Grid container spacing={2} padding={2}>
            {docList !== undefined &&
              docList.map((item, idx) => (
                <Grid item xs={12} sm={6} md={4} key={idx}>
                  <Card sx={style.docbarCard}>
                    <CardActionArea onClick={() => { setSelected(item, setAddDoctor(false)) }} sx={style.cardActionArea}>
                      <Typography variant='h6' sx={style.docNameTypo}>{item}</Typography>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))
            }
            
          </Grid>
          
          <Card sx={style.addDocCard}>
          <CardActionArea onClick={() => { setAddDoctor(true) }} sx={style.addDocCardActionArea}>
            <Typography sx={style.addDocTypo}><>Add a Doctor  
              <Tooltip  title={<h2 sx={style.toolTip}>Add a new doctor with one new procedure</h2>} arrow>
                <InfoIcon sx={style.secondaryInfoIcon}  />
              </Tooltip></></Typography>
          </CardActionArea>
        </Card>
      
        </Stack>
      </Box>
      {
        !addDoctor ?
          <Box sx={style.selectADocBox}>
            <Box sx={style.selectADocBox2}>
              <Box sx={style.selectADocBox3}>
              <Typography sx={style.selectADocBoxTypo}>
                {selected.length === 0 ? (
                    <>
                      Select a doctor
                      <Tooltip title={<h2 style={style.toolTip}>Choose from a list on the left</h2>} arrow>
                        <InfoIcon sx={style.doctorInfoIcon} />
                      </Tooltip>
                    </>
                  ) : (selected)}</Typography>
              </Box>
{/* The 4 boxes & lists for showcased doctor */}
              <Box sx={style.selectADoclistBox}>
                <Stack direction='row' sx={style.selectADoclistStack}>
                  <Typography sx={style.selectADoclistHeaderTypo}>Procedure Code</Typography>
                  <Typography  sx={style.selectADoclistHeaderTypo}>Fee</Typography>
                  <Typography  sx={style.selectADoclistHeaderTypo}>Supply Cost</Typography>
                  <Typography  sx={style.selectADoclistHeaderTypo}>Average Time</Typography>
                </Stack>
                <Box sx={style.selectADoclistMappingBox}>
                  {
                    selected.length > 0 &&
                    paginatedData.map((doc, idx) => (
                    <Box key={idx} sx={{backgroundColor: idx % 2 === 0 ? 'lightgrey' : 'white', borderRadius:'5px',padding:'3px',marginTop:'5px',display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center',}}>
                      <Typography variant="body1" sx={style.selectADoclistHeaderTypo}>{doc.procedure}</Typography>
                      <Typography variant="body1" sx={style.selectADoclistHeaderTypo}>{doc.surgeon_fee}</Typography>
                      <Typography variant="body1" sx={style.selectADoclistHeaderTypo}>{doc.supplies}</Typography>
                      <Typography variant="body1" sx={style.selectADoclistHeaderTypo}>{doc.avg_time}</Typography>
                    </Box> 
                  ))}
                </Box>
                { selected.length > 0 ? 
                <Pagination
                  sx={style.pagination}
                count={pageMax}
                page = {currentPage}
                onChange={(e, value) => setCurrentPage(value)}
              /> :
              <Box></Box> }
              </Box>
{/* Add button after the 4 boxes */}
              <Box sx={style.docProcedureButtonBox}>
                {
                  !procedureAdd ?
                  <>
                    <Button sx={style.docProcedureAddButton} onClick={() => { setProcedureAdd(true) }} variant='contained'>
                    <>Add <Tooltip title={<h2 sx={style.toolTip}>Add a new procedure for {selected.length>1 ? selected : 'a doctor'}</h2>} arrow>
                      <InfoIcon sx={style.secondaryInfoIcon} />
                    </Tooltip></></Button>
                    {
                      selected.length>0 &&
                      <Button onClick={()=>{removeDoctor()}}sx={style.docRemoveButton}>
                        <>Remove<Tooltip title={<h2 sx={style.toolTip}>Delete Selected Doctor</h2>} arrow>
                      <InfoIcon sx={style.secondaryInfoIcon} />
                    </Tooltip></>
                      </Button>
                    }
                    </>
                    :
                    <Box >
                      <Grid container sx={style.docContainerProcAdd}>
                        <Grid item xs={6} sx={{position:'relative',margin:'5px'}}>
                          <Typography sx={style.newProcTypo}>
                            New code
                          </Typography>
                          <Box sx={{ width:'100%'}}>
                            <TextField sx={style.docAddProcTextField} value={code} onChange={(e) => { const value = e.target.value.replace(/[^0-9.]/g, '');setCode(value); setDropDown(true); }} />
                            <Box  ref={boxRef} sx={{
                                position: 'absolute', borderRadius:'10px',
                                top:"70px",
                                backgroundColor: 'white', // White background
                                width: '100%', // Match TextField width
                                //border: '1px solid lightgray', // Optional border
                                zIndex: 1, // Ensure it's above other content
                              }}>
                                {code.length>0 && procedureList.length > 0 && dropDown &&
                                  procedureList.filter(item => item.startsWith(code))
                                  .map((item, idx) => (
                                    <Typography
                                      key={idx}
                                      sx={{color: 'black', padding: '4px', cursor: 'pointer', borderRadius:'10px', '&:hover': {
                                        backgroundColor: 'lightgrey', cursor: 'pointer', transform: 'scale(1.05)',  cursor: 'pointer',
                                    },transition: 'all 0.3s ease',}} 
                                      onClick={() => setCode(item,setDropDown(false))} // Fill TextField on click
                                    >
                                      {item}
                                    </Typography>
                                  ))
                                }
                          </Box>
                          </Box>
                        </Grid>
                          <Grid item  xs={6}  sx={{margin:'5px', }}>
                            <Typography sx={style.newProcTypo}>
                              Fee cost
                            </Typography>
                            <TextField sx={style.docAddProcTextField} value={fee} onChange={(e) => {const value = e.target.value.replace(/[^0-9.]/g, ''); setFee(value) }}  />
                          </Grid>
                        
                      
                          <Grid item xs={6}  sx={{margin:'5px'}}>
                            <Typography sx={style.newProcTypo}>
                              Supply cost
                            </Typography>
                            <TextField sx={style.docAddProcTextField} value={supply} onChange={(e) => {const value = e.target.value.replace(/[^0-9.]/g, ''); setSupply(value) }}  />
                          </Grid>
                          <Grid item xs={6}  sx={{margin:'5px'}}>
                            <Typography sx={style.newProcTypo}>
                              Average time
                            </Typography>
                            <TextField sx={style.docAddProcTextField} value={time} onChange={(e) => {const value = e.target.value.replace(/[^0-9.]/g, ''); setTime(value) }} />
                          </Grid>
                        </Grid>
                     
                      <Box sx={style.docAddProcComfirmBox}>
                      <Button sx={style.docAddProcConfirmButton}variant='contained' onClick={() => setProcedureAdd(false)}>Cancel</Button>
                      <Button sx={style.docAddProcConfirmButton}variant='contained' onClick={() => addProcedure()}>Add</Button>
                      </Box>
                    </Box>
                }
              </Box>
            </Box>
          </Box>
          :
          <Box sx={style.addADocBox}>
{/* Else, show the add a doctor page */}
            <Stack direction='row' sx={style.addADocBox2}>
              <Typography variant='h4'sx={style.addDocTypo2}>
                <>
                Add a Doctor
                <Tooltip  title={<h2 sx={style.toolTip}>Add the doctor's name with their coresponding procedure code, fee, cost, and average time.</h2>} arrow>
              <InfoIcon sx={style.thirdInfoIcon}  />
              </Tooltip>
              </>
              </Typography>
             
            </Stack>

            <Box sx={{marginLeft:'auto',marginRight:'auto', marginBottom:'20px', marginTop:'50px'}}>
              <Typography  sx={style.newProcTypo}>
                Doctor
              </Typography>
              <TextField   sx={style.docAddProcTextField}  value={doctorName} onChange={(e) => {const value = e.target.value.replace(/[^a-zA-Z. ]/g, ''); setDoctorName(value) }} />
            </Box>

          <Grid container spacing={2} sx={{ padding:'10px',display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid item xs={12} md={6} sx={style.addADocInitalProcAddGridItem}>
              <Typography sx={style.addADocInitalProcAddTypo}>
                Procedure code
              </Typography>
        
              <TextField
                sx={style.addADocInitalProcAddTextField}
                value={code}
                onChange={(e) => {const value = e.target.value.replace(/[^0-9.]/g, ''); setCode(value); setDropDown(true)}}
              />
              <Box  ref={boxRef} sx={{                  
                position: 'absolute', // Position right under TextField
                top:"100px", borderRadius:'10px 10px 10px 10px', 
                backgroundColor: 'white', // White background
                width: '70%', // Match TextField width
                zIndex: 1, // Ensure it's above other content
                }}>
                  {code.length>0 && procedureList.length > 0 && dropDown &&
                  procedureList.filter(item => item.startsWith(code))
                  .map((item, idx) => (
                  <Typography
                   key={idx}
                   sx={{color: 'black', padding: '4px', cursor: 'pointer', borderRadius:'10px', '&:hover': {
                    backgroundColor: 'lightgrey', cursor: 'pointer', transform: 'scale(1.05)',  cursor: 'pointer',
                },transition: 'all 0.3s ease',}} 
                   onClick={() => setCode(item,setDropDown(false))} // Fill TextField on click
                    >
                    {item}
                    </Typography>
                  ))
                  }
              
              </Box>
            </Grid>
              <Grid item xs={12} md={6} sx={style.addADocInitalProcAddGridItem}>
                <Typography sx={style.addADocInitalProcAddTypo}>
                  Fee
                </Typography>
                <TextField
                  sx={style.addADocInitalProcAddTextField}
                  value={fee}
                  onChange={(e) => {const value = e.target.value.replace(/[^0-9.]/g, ''); setFee(value) }}
                />
              </Grid>
              <Grid item xs={12} md={6}  sx={style.addADocInitalProcAddGridItem}>
                <Typography sx={style.addADocInitalProcAddTypo}>
                  Supply cost
                </Typography>
                <TextField
                  sx={style.addADocInitalProcAddTextField}
                  value={supply}
                  onChange={(e) => {const value = e.target.value.replace(/[^0-9.]/g, ''); setSupply(value) }}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={style.addADocInitalProcAddGridItem}>
                <Typography sx={style.addADocInitalProcAddTypo}>
                  Avg Time
                </Typography>
                <TextField
                  sx={style.addADocInitalProcAddTextField}
                  value={time}
                  onChange={(e) => {const value = e.target.value.replace(/[^0-9.]/g, ''); setTime(value) }}
                />
              </Grid>
            </Grid>
            <Stack direction="row" sx={style.addADocComfirmStack}>
            <Button sx={style.docAddProcConfirmButton}onClick={() => { doctorSubmit() }}> Add Doctor</Button>
        <Button sx={style.docAddProcConfirmButton} onClick={() => { setAddDoctor(false) }} >Cancel</Button>
            </Stack>
          </Box>
      }
    </Stack>
 
  </Box>
  )
}

export default Doctor
