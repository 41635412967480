import { Box, Typography,Grid,TextField, Button, Stack, Pagination} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Navbar } from '../component'
import { convertToObject, getData, postData } from '../functions'
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import styles from '../styles';

const Hospital = ({ setSavedData, setDoctorData,facilityData, setFacilityData, setProcedureInfo, procedureInfo}) => {
  
  const style = styles;

  const [tempSigninData, settempSigninData] = useState([])
  const [needsUpdated, setNeedsUpdated] = useState(false)
  const [anesthesia, setAnesthesia] = useState('')
  const [staffExpense, setStaffExpense] = useState('')
  const [facility, setFacility] = useState('')
  const [RaBC, setRaBC] = useState('')

  const [addProcedure, setAddProcedure] = useState(false)

  const [code, setCode] = useState('')
  const [procedure, setProcedure] = useState('')
  const [implants, setImplants] = useState('')
  //initalize set state for me
  const [userid, setUserid] = useState('')
  const [trigger, setTrigger] = useState(false)
  // pagination states
  const [pageMax, setPageMax] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  // had to add one more because PageMax's number will be wrong if you use the total list of entire 
  const [filteredProcedureInfo, setFilteredProcedureInfo] = useState([]);
  // to store the new filtered list
  const [paginatedArray, setPaginatedArray] = useState([])
  // implant edit index
  const [editIdx, setEditIdx] = useState(-1)
  const [proc, setProc] = useState([])
  const [newCost, setnewCost] = useState(0)

  const navigate = useNavigate()


  function addCode(){
    let codes = 0
    if(facilityData.account_type === 'demo'){
      for(let i = 0; i<procedureInfo.length;i++){
        if(procedureInfo[i].user_id === facilityData.id ){
          
          codes += 1
        }
      }
    }
    if(code !== '' && procedure !=='' && implants!=='' && codes <5){
      let temp = procedureInfo
      let id = Number(procedureInfo[procedureInfo.length-1].id)
      let ind = {id:id+1, procedure_code:code,procedure_or_surgery:procedure,implant_cost:implants, user_id:facilityData.id}
      temp.push(ind)
      
      setProcedureInfo(temp)
      postData(ind, 'pricing')
      setAddProcedure(false)
    } else if(codes > 5) {
      alert('Maximum Codes reached for this demo')
    } else{
      alert('Field(s) Not Complete')
    }
  }
  // updates hourly costs
  function update(){
   
    let temp = facilityData
    if(anesthesia !== temp.anesthesia_hourly){
      temp.anesthesia_hourly = anesthesia
      postData({table:'priceh_user',id:facilityData.id,columnToUpdate:'anesthesia_hourly',newValue:anesthesia},'update')
    }
    if(staffExpense !== temp.staff_expense_hourly){
      temp.staff_expense_hourly = staffExpense
      postData({table:'priceh_user',id:facilityData.id,columnToUpdate:'staff_expense_hourly',newValue:staffExpense},'update')
    }
    if(facility !== temp.facility_hourly){
      temp.facility_hourly = facility
      postData({table:'priceh_user',id:facilityData.id,columnToUpdate:'facility_hourly',newValue:facility},'update')
    }
    if(RaBC !== temp.room_and_board_cost){
      temp.room_and_board_cost = RaBC
      postData({table:'priceh_user',id:facilityData.id,columnToUpdate:'room_and_board_cost',newValue:RaBC},'update')
    }
    
    setFacilityData(temp)
    setNeedsUpdated(false)
    
  }
  
// My way of fixing the RaBC cost not updating on reloads. 
  useEffect(() => {
    getData('passwords',settempSigninData)
  }, [])
  
  useEffect(() => {
    if(tempSigninData.length > 2 && typeof(tempSigninData)!=='object'){
      let user = window.localStorage.getItem('user');
      user = JSON.parse(user);
      let temp = convertToObject(tempSigninData,['id','user','password','company_name','anesthesia_hourly','staff_expense_hourly', 'facility_hourly', 'email','status','account_type', 'room_and_board_cost'])
      settempSigninData(temp)
      setRaBC(temp[user.id-1].room_and_board_cost)
      setAnesthesia(temp[user.id-1].anesthesia_hourly);
      setStaffExpense(temp[user.id-1].staff_expense_hourly);
      setFacility(temp[user.id-1].facility_hourly);
    }
  }, [tempSigninData])

  useEffect(() => {
   
    if (facilityData.user === undefined) {
      let user = window.localStorage.getItem('user');
      user = JSON.parse(user);
      if(user !== null){
        setFacilityData(user);
        getData('records', setSavedData)
        getData('doctors', setDoctorData, Number(user.id));
        getData('pricing', setProcedureInfo); 
        //setAnesthesia(user.anesthesia_hourly);
        //setStaffExpense(user.staff_expense_hourly);
        //setFacility(user.facility_hourly);
        //setRaBC(user.room_and_board_cost);
      } else{
        navigate()
      }
      
      
    } else {
      setAnesthesia(facilityData.anesthesia_hourly)
      setStaffExpense(facilityData.staff_expense_hourly)
      setFacility(facilityData.facility_hourly)
      setRaBC(facilityData.room_and_board_cost)
    }
    
  }, []);
  
  

  useEffect(() => { 
    if (procedureInfo.length > 0 && typeof (procedureInfo[0]) === 'string') {
      setTrigger(!trigger)
      setProcedureInfo(convertToObject(procedureInfo, ['id', 'procedure_code', 'procedure_or_surgery', 'implant_cost', 'user_id']));
    } else if(typeof (procedureInfo[0]) === 'object'){
      
     
      // filteres procedure info to contain procedures specific to the user id
      const filteredProcedureInfo = procedureInfo.filter(item => item.user_id === facilityData.id);

      setFilteredProcedureInfo(filteredProcedureInfo)
      // Slice the filtered data based on the current page
      const startIndex = (currentPage - 1) * 6;
      const endIndex = startIndex + 6;
      // get all the indexes in between, according to the current page
      const paginatedData = filteredProcedureInfo.slice(startIndex, endIndex);
      setPaginatedArray(paginatedData)
    }
  }, [procedureInfo,trigger, currentPage]);

  // sets the max amounts of pages for pagination
  useEffect(() => {
    setPageMax( Math.ceil(filteredProcedureInfo.length / 6))
    
  }, [filteredProcedureInfo])

  
  function editImplants(idx,proc){
    if(idx === editIdx){
      setnewCost(0)
      setEditIdx(-1)
      setProc([])
    }else{
      setnewCost(proc.implant_cost)
      setEditIdx(idx)
      setProc(proc)
    }
  }
  function updateImplants(){
    for(let i = 0; i<procedureInfo.length; i++){
      if(procedureInfo[i].id == proc.id){
        procedureInfo[i].implant_cost = newCost
      }
    }
    postData({table:'priceh_surgical_pricing',id:proc.id,columnToUpdate:'implant_cost',newValue:newCost},'update')
    setEditIdx(-1)
    setnewCost(0)
  }
  
  useEffect(() => {
    postData({table:'database_record',id:0,columnToUpdate:'implant_cost',newValue:newCost},'update')
  }, [])

  
  
   return (
    <Box>
    <Navbar user={facilityData}/>
    <Stack direction={'row'} sx={style.hospitalMainStack}>
    <Box sx={style.hospitalMainBox} >
      <Box sx={style.hospitalMainBox2}>
        <Box sx={style.expensesTypoBox}>
            <Typography variant='h4'>Expenses</Typography>
        </Box>
    
      <Grid container direction="column" sx={style.firstHospitalGridContainer}>
        <Grid>
          
          <Typography sx={style.anesthesiaHourTypo}>
            Anesthesia (hourly)
          </Typography>
          <TextField sx={style.hospitalHourlyTextField} disabled={!needsUpdated} onChange={(e)=>{const value = e.target.value.replace(/[^0-9.]/g, ''); setAnesthesia(value)}} value={anesthesia}/>
        </Grid>
        <Grid >
          <Typography sx={style.staffExpenseHourTypo}>
            staff expense (hourly)
          </Typography>
          <TextField sx={style.hospitalHourlyTextField} disabled={!needsUpdated} onChange={(e)=>{const value = e.target.value.replace(/[^0-9.]/g, '');setStaffExpense(value)}} value={staffExpense}/>
        </Grid>
        <Grid>
          <Typography sx={style.facilityCostHourTypo}>
            Facility Costs (hourly)
          </Typography>
          <TextField sx={style.hospitalHourlyTextField} disabled={!needsUpdated} onChange={(e)=>{const value = e.target.value.replace(/[^0-9.]/g, '');setFacility(value)}} value={facility}/>
            {/* Room and Board Cost */}
          <Typography sx={style.RaBCTypo}>
            Room and Board Cost (Daily) 
          </Typography>
          <TextField sx={style.hospitalHourlyTextField} disabled={!needsUpdated} onChange={(e)=>{const value = e.target.value.replace(/[^0-9.]/g, ''); setRaBC(value)}} value={RaBC}/>
        </Grid>
      </Grid>
      
      <Box sx={style.hospitalProcComfirmBox}>
      {
        needsUpdated ?
      <Button sx={style.hospitalProcComfirmButton}onClick={()=>{update()}} variant='contained'>
        Confirm
      </Button>
        :
      <Button  sx={style.hospitalProcButtonUpdateButton}onClick={()=>{setNeedsUpdated(true)}} variant='contained'>
        <>Update <Tooltip  title={<h2 style={style.toolTip}>Update your hourly expenses</h2>} arrow>
                  <InfoIcon sx={style.secondaryInfoIcon}  />
                </Tooltip></>
      </Button>
      }
      </Box>
    </Box>
  </Box>
{/* Spilt Between  Hourly Costs and Procedures */}
<Box sx={style.hourlyBox} />
    <Box sx={style.hourlyBox2}>
      <Box sx={style.hourlyBox3}>
        <Box sx={style.hourlyBox4}>
            <Typography variant='h4'>Procedures</Typography>
        </Box>
      { 
        paginatedArray.length > 0 && typeof (paginatedArray[0]) === 'object' &&
    <Box sx={style.hospitalArrayBox}>
      <Pagination
          sx={{
            ml: 'auto', mr: 'auto',
            '& .MuiPaginationItem-root': {
              color: 'black', // Color of the pagination items
            },
            '& .MuiPaginationItem-page.Mui-selected': {
              backgroundColor: 'lightgrey', // Background color of the selected page
              color: 'black', // Color of the text in the selected page
            },
            '& .MuiPaginationItem-page:hover': {
              backgroundColor: 'lightgrey', // Background color when hovered
              color: 'white', // Text color when hovered
            }
          }}
        count={pageMax}
        page = {currentPage}
        onChange={(e, value) => setCurrentPage(value)}
      /> 
      <Stack direction='row' sx={style.hospitalArrayHeaderStack}>
        <Typography sx={style.hospitalArrayTypo}>Procedure Code</Typography>
        <Typography sx={style.hospitalArrayTypo}>Procedure Name</Typography>
        <Typography sx={style.hospitalArrayTypo}>Implant Cost</Typography>
      </Stack>
      
        <Box sx={style.hospitalMappingBox}>
        {
        paginatedArray.map((proc, idx) => (
          <Box key={idx} sx={{ borderRadius:'5px',padding:'3px',marginTop:'5px',display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', backgroundColor: idx % 2 === 0 ? 'white' : 'lightgray', }}>
            <Typography variant="body1" sx={style.hospitalArrayTypo}>{proc.procedure_code}</Typography>
            <Typography variant="body1" sx={style.hospitalArrayTypo}>{proc.procedure_or_surgery}</Typography>   
            {
              editIdx === idx ? 
                <TextField sx={style.hospitalEditTextField} value={newCost} onChange={(e)=>{const value = e.target.value.replace(/[^0-9.]/g, ''); setnewCost(value)}}/>
              :
                <Typography variant="body1" sx={style.hospitalArrayTypo}>{proc.implant_cost}</Typography>
            }
             <EditIcon onClick={()=>editImplants(idx, proc)}/>
          </Box>
        ))}
        {
          editIdx != -1 &&
          <Button onClick={()=>updateImplants()}  sx={style.hospitalProcListSaveButton}>Save Changes</Button>
        }   
      </Box>
    </Box>
    }
      {/* Add button */}
      <Box sx={style.hospitalProcListAddBox}>
        {
          !addProcedure ?
          <Button sx={style.hospitalProcListAddButton}onClick={()=>{setAddProcedure(true)}}><>Add 
              <Tooltip  title={<h2 sx={style.toolTip}> Make an addition to your list of procedures</h2>} arrow>
                <InfoIcon sx={style.secondaryInfoIcon}  />
              </Tooltip></>
          </Button>
          :
          <Stack sx={style.hospitalProcAddFieldsStack}>
          <Grid container columns={3} sx={style.hospitalProcAddContainer}>
            <Grid sx={style.hospitalProcAddGridItem}>
              <Typography sx={style.hospitalProcAddTypo}>
                Procedure Code
              </Typography>
              <TextField sx={style.hospitalProcAddTextField} value={code} onChange={(e)=>{const value = e.target.value.replace(/[^0-9.]/g, ''); setCode(value)}}/>
            </Grid>
            <Grid sx={style.hospitalProcAddGridItem}>
              <Typography sx={style.hospitalProcAddTypo}>
                Procedure Name
              </Typography>
              <TextField sx={style.hospitalProcAddTextField}  value={procedure} onChange={(e)=>{const value = e.target.value.replace(/[^a-zA-Z0-9. ]/g, ''); setProcedure(value)}}/>
            </Grid>
            <Grid sx={style.hospitalProcAddGridItem2}>
              <Typography sx={style.hospitalProcAddTypo}>
                Implant Cost
              </Typography>
              <TextField sx={style.hospitalProcAddTextField} value={implants} onChange={(e)=>{const value = e.target.value.replace(/[^0-9.]/g, ''); setImplants(value)}}/>  
            </Grid>
          </Grid>
          <Stack direction='row' sx={style.hospitalAddorCancelStack}>
            <Button sx={style.hospitalProcListAddButton}onClick={()=>{addCode()}}>Add</Button>
            <Button  sx={style.hospitalProcListAddButton}onClick={()=>{setAddProcedure(false)}}>Cancel</Button>
          </Stack>
          </Stack>
        }
      
      </Box>
      </Box>
    </Box>
    </Stack>
  </Box>
  )
}

export default Hospital