import { BrowserRouter, Routes, Route } from 'react-router-dom'
import {CostAnalysis, Doctor, Home, Hospital, Comparisons, Saved, SignIn, SubmitcPDF, Admin} from './pages'
import { useEffect, useState } from 'react';
import {getData } from './functions';

// to change the font
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';

function App() {
  //Sign in to get the user\
    
    const [signInData, setSignInData] = useState([])
    const [doctorData, setDoctorData] = useState([])
    const [procedureInfo, setProcedureInfo] = useState([])
    const [facilityData, setFacilityData] = useState([])
    const [savedData, setSavedData] = useState([])


    //for pdf data conversion
    const [pdfCompData, setPdfCompData] = useState([])

    // moved from comparisons so i can use it for the pdf
    const [procedure, setProcedure] = useState('')
    useEffect(() => {
        getData('passwords',setSignInData)
       
        getData('pricing',setProcedureInfo)
        
      }, [])
    
    // to change font family
    const theme = createTheme({
      typography: {
        fontFamily: [
          'Nunito',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif'
        ].join(','),
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
          xxl: 1900
        },
      },
    }); 
 /*   
    useEffect(() => {
        getData('passwords',setUserData)
        
      }, [])
    useEffect(() => {
        if(userData === ''){
            getData('passwords',setUserData)
        }
      }, [userData])
*/
    return (
        <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<SignIn
                
                setSavedData={setSavedData}
                setFacilityData={setFacilityData}
                signInData={signInData}
                setSignInData={setSignInData}
                setProcedureInfo={setProcedureInfo}
                procedureInfo={procedureInfo}
                setDoctorData={setDoctorData}
                
                />} />
                <Route path={'/costAnalysis'} element={<CostAnalysis
                    
                    setSavedData={setSavedData}
                    savedData={savedData}
                    setProcedureInfo={setProcedureInfo}
                    doctorData={doctorData}
                    setDoctorData={setDoctorData}
                    procedureInfo={procedureInfo}
                    facilityData={facilityData}
                    setFacilityData={setFacilityData}

                />} />
                
                <Route path={'/doctor'} element={
                    <Doctor
                    
                        savedData={savedData}
                        setSavedData={setSavedData}
                        doctorData={doctorData}
                        setDoctorData={setDoctorData}
                        facilityData={facilityData}
                        setFacilityData={setFacilityData}
                        setProcedureInfo={setProcedureInfo}
                        procedureInfo={procedureInfo}
                    />} />
                <Route path={'/facility'} element={<Hospital 
                
            
                facilityData={facilityData} 
                setFacilityData={setFacilityData}
                procedureInfo={procedureInfo}
                setProcedureInfo=
                {setProcedureInfo}
                setDoctorData={setDoctorData}
                setSavedData={setSavedData}
                />} />
                <Route path={'/procedure'} element={<Comparisons procedureInfo={procedureInfo} setProcedureInfo={setProcedureInfo}
               
                doctorData={doctorData}
                setDoctorData={setDoctorData}
                facilityData={facilityData}
                setFacilityData={setFacilityData}
                setSavedData={setSavedData}
                setPdfCompData={setPdfCompData}
                pdfCompData={pdfCompData}
                setProcedure={setProcedure}
                procedure={procedure}
                />} />
                <Route path={'/saved'} element={<Saved
            
                  savedData={savedData}
                  setSavedData={setSavedData}
                  facilityData={facilityData}
                  setFacilityData={setFacilityData}
                  setProcedureInfo={setProcedureInfo}
                  setDoctorData={setDoctorData}
                  setProcedure={setProcedure}
                  procedure={procedure}
                />} />

                <Route path={'/SubmitcPDF'} element={<SubmitcPDF facilityData={facilityData} procedure={procedure} pdfCompData={pdfCompData} setPdfCompData={setPdfCompData}/>} />
                
                <Route path={'/admin'} element={<Admin facilityData={facilityData} setFacilityData={setFacilityData}/>} />
                


            </Routes>
        </BrowserRouter>
        </ThemeProvider>
    );
}
 
export default App;