import { Box, Button, Grid, MenuItem, Select, Stack, TextField, Typography,InputAdornment,  } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import { Navbar } from '../component'
import { convertToObject, getData } from '../functions'
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom'
import styles from '../styles';

const Procedure = ({setProcedure, procedure, procedureInfo, setProcedureInfo, doctorData, setDoctorData, facilityData, setFacilityData, setSavedData, setPdfCompData, pdfCompData}) => {
  const style = styles;
  const boxRef = useRef();

  const [aDoc, setADoc] = useState('Select Doctor')
  const [aDocData, setADocData] = useState({full:0, time:0,costPH:0})

  const [bDoc, setBDoc] = useState('Select Doctor')
  const [bDocData, setBDocData] = useState({full:0, time:0,costPH:0})

  const [cDoc, setCDoc] = useState('Select Doctor')
  const [cDocData, setCDocData] = useState({full:0, time:0,costPH:0})

  const [aDocVis, setADocVis] = useState(true)
  const [bDocVis, setBDocVis] = useState(true)
  const [cDocVis, setCDocVis] = useState(true)


  const [docsList, setdocsList] = useState([])
  const [trigger, setTrigger] = useState(false)


  const [procedureList, setProcedureList] = useState([])
  const [dropDown, setDropDown] = useState(false)

  const navigate = useNavigate()
  function outputData(setData, data, setDoctor, value, procedureVal = ''
  ){
    setDoctor(value)
    let proceduresList = []
    let implants = 0

    for(let i = 0; i < procedureInfo.length;i++){
      proceduresList.push(procedureInfo[i].procedure_code)
    }
    if(proceduresList.includes(procedure) || proceduresList.includes(procedureVal)){
      for(let i = 0; i < procedureInfo.length;i++){
        if(procedureVal === ''){
          if(procedureInfo[i].procedure_code === procedure){
            implants = procedureInfo[i].implant_cost
          }
        } else{
          
          if(procedureInfo[i].procedure_code === procedureVal){
            implants = procedureInfo[i].implant_cost
          }
        }
        
      }
      if(procedureVal === ''){
        let docFound = false
        for(let i = 0; i < doctorData.length;i++){
          if(doctorData[i].doctor === value && doctorData[i].procedure === procedure){
            let temp = data
            let fullCost = Number(doctorData[i].surgeon_fee) + Number(doctorData[i].supplies) + Number(facilityData.anesthesia_hourly * doctorData[i].avg_time/60) + Number(facilityData.staff_expense_hourly * doctorData[i].avg_time/60) + Number(facilityData.facility_hourly * doctorData[i].avg_time/60) + Number(implants)
            fullCost = (fullCost * 1.13).toFixed(2)
  
            temp.full = fullCost
            temp.time = doctorData[i].avg_time
            temp.costPH = ((Number(doctorData[i].surgeon_fee) + Number(doctorData[i].supplies) + Number(facilityData.anesthesia_hourly) + Number(facilityData.staff_expense_hourly) + Number(facilityData.facility_hourly) + Number(implants)) * 1.13).toFixed(2)
            setData(temp)
            docFound = true
          } 
        }
        if(docFound === false ){         
            setData({full:0,time:0,costPH:0})
          
        }
      } else {
        let docFound = false
        for(let i = 0; i < doctorData.length;i++){
          if(doctorData[i].doctor === value && doctorData[i].procedure === procedureVal){
            let temp = data
            let fullCost = Number(doctorData[i].surgeon_fee) + Number(doctorData[i].supplies) + Number(facilityData.anesthesia_hourly * doctorData[i].avg_time/60) + Number(facilityData.staff_expense_hourly * doctorData[i].avg_time/60) + Number(facilityData.facility_hourly * doctorData[i].avg_time/60) + Number(implants)
            fullCost = (fullCost * 1.13).toFixed(2)
  
            temp.full = fullCost
            temp.time = doctorData[i].avg_time
            temp.costPH = ((Number(doctorData[i].surgeon_fee) + Number(doctorData[i].supplies) + Number(facilityData.anesthesia_hourly) + Number(facilityData.staff_expense_hourly) + Number(facilityData.facility_hourly) + Number(implants)) * 1.13).toFixed(2)
            setData(temp)
            docFound = true
          }
        }
        if(docFound === false){
          
            setData({full:0,time:0,costPH:0})
          
        }
      }
      
    }
  }


   // to hide dropdown if mousedown somewhere else on the screen
   useEffect(() => {
    function handleClickOutside(event) {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setDropDown(false); 
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); 
  
  useEffect(() => {
    if (doctorData.length > 0 && typeof (doctorData[0]) !== 'object') {
      setDoctorData(convertToObject(doctorData, ['id','user_id', 'doctor', 'procedure', 'surgeon_fee', 'supplies', 'avg_time']));
    }
    if (typeof (doctorData[0]) === 'object') {
      let temp = [];
      for (let i = 0; i < doctorData.length; i++) {
        if (!temp.includes(doctorData[i].doctor)) {
          temp.push(doctorData[i].doctor);
        }
      }
      setdocsList(temp);
    } else {
      setTrigger(!trigger);
    }
  }, [doctorData, trigger]);

  useEffect(() => {
    if (procedureInfo.length > 0 && typeof (procedureInfo[0]) !== 'object') {
      setProcedureInfo(convertToObject(procedureInfo, ['id', 'procedure_code', 'procedure_or_surgery', 'implant_cost', 'user_id']));
     
    }
    if(procedureInfo.length > 0 && typeof (procedureInfo[0]) === 'object'){
  
      let temp = []
      for(let i = 0; i<procedureInfo.length; i++){
        if(procedureInfo[i].user_id === facilityData.id){
          temp.push(procedureInfo[i].procedure_code)
        }    
      }
      setProcedureList(temp)
    }
  }, [procedureInfo]);

  useEffect(() => {
    setADoc('Select Doctor')
    setBDoc('Select Doctor')
    setCDoc('Select Doctor')
    if (facilityData.user === undefined) {
      let user = window.localStorage.getItem('user');
      user = JSON.parse(user);
      setFacilityData(user);
      getData('doctors', setDoctorData, Number(user.id));
      getData('pricing', setProcedureInfo);
      getData('records', setSavedData);
    }
  }, []);

//const filteredProcedureInfo = procedureInfo.filter(item => item.user_id === facilityData.id);
// combines the 3 comparable doctor data into 1 usestate to be sent to submitcPDF
  const handlePDFSubmit = () => {
    // these 2 consts check if the procedure that was inputed even exists, to prevent the pdf having non existant codes
    const filteredProcedureInfo = procedureInfo.filter(item => item.user_id === facilityData.id)
    const result = filteredProcedureInfo.find(item => item.procedure_code === procedure);
    if (result) {
      if (procedure.length >= 4 && (aDoc && aDoc !== 'Select Doctor') && (bDoc && bDoc !== 'Select Doctor') && (cDoc && cDoc !== 'Select Doctor')) {
      const tempADocDataWithName = { name: aDocVis ?  aDoc : '***', ...aDocData };
      const tempBDocDataWithName = { name: bDocVis ?  bDoc : '***' , ...bDocData };
      const tempCDocDataWithName = { name: cDocVis ?  cDoc : '***' , ...cDocData };
      setPdfCompData([tempADocDataWithName, tempBDocDataWithName, tempCDocDataWithName]);
      navigate('/SubmitcPDF')
      } else {
        alert('Fill in all 3 doctor slots')
      }
    } else {
      alert('Input valid CPT code')
    }
  }


  function changeProcedure(value) {
    setProcedure(value);
    outputData(setADocData, aDocData, setADoc, aDoc, value);
    outputData(setBDocData, bDocData, setBDoc, bDoc, value);
    outputData(setCDocData, cDocData, setCDoc, cDoc, value);
  }
  
  const handleDropdownClick = (item) => {
    setProcedure(item)
   
    outputData(setADocData,aDocData,setADoc,aDoc, item)
    outputData(setBDocData,bDocData,setBDoc,bDoc, item)
    outputData(setCDocData,cDocData,setCDoc,cDoc, item)
    
  }
  return (
    <Box sx={style.mainBox}>
    <Navbar />
    <Box sx={style.secondaryBox}>
      <Typography sx={style.printPDFTypo}>
        <Button sx={style.printPDFButton} variant='contained' onClick={() =>{handlePDFSubmit()}}>
            Print PDF
        </Button>
      </Typography>
      <Typography sx={style.procedureCodeTypo}>
        Procedure Code
      </Typography>
      <Box sx={style.boxAfterProcedureCodeText}>
        <TextField
          sx={style.procedureCodeTextField}
          value={procedure}
          onChange={(e) => { const value = e.target.value.replace(/[^0-9.]/g, ''); changeProcedure(value) ; setDropDown(true)}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip  title={<h2 style={style.toolTip}>Type in procedure code</h2>} arrow>
                  <InfoIcon sx={style.infoIcon}  />
                </Tooltip>
              </InputAdornment>)}}
        />
               <Box ref={boxRef} sx={{
                  position: 'absolute', // Position right under TextField
                  top:"60px",
                  borderRadius:'10px',
                  backgroundColor: 'white', // White background
                  width: '85%', // Match TextField width
                  //border: '1px solid lightgray', // Optional border
                  zIndex: 1, // Ensure it's above other content
                }}>
                  {procedure.length>0 && procedureList.length > 0 && dropDown &&
                    procedureList.filter(item => item.startsWith(procedure))
                    .map((item, idx) => (
                      <Typography
                        key={idx}
                        sx={{color: 'black', padding: '4px', cursor: 'pointer', borderRadius:'10px', '&:hover': {
                          backgroundColor: 'lightgrey', cursor: 'pointer', transform: 'scale(1.05)',  cursor: 'pointer',
                      },transition: 'all 0.3s ease',}} 
                        onClick={() => setProcedure(item,setDropDown(false))} // Fill TextField on click
                      >
                        {item}
                      </Typography>
                    ))
                  }
            </Box>
      </Box>
      <Typography sx={style.profitMarginTypo}>
        13% profit margin added
      </Typography>
      <Box sx={style.spacerBox}></Box>
    <Grid container sx={style.blueBackgroundContainer}>
    <Grid item xs={12} sm={12} md={3} sx={style.docGridItem}>
        <Typography sx={style.docTypo}>Doctor 1 {aDocVis ? <VisibilityIcon onClick={()=>setADocVis(false)}/> : <VisibilityOffIcon  onClick={()=>setADocVis(true)}/>}</Typography>
        <Box sx={style.docBox}>
          <Select  MenuProps={{ disableScrollLock: true }} value={aDoc} onChange={(e) => { outputData(setADocData, aDocData, setADoc, e.target.value) }}>
            <MenuItem value={aDoc}>Select Doctor</MenuItem>
            {docsList.map((item, idx) => (
              <MenuItem key={idx} value={item}>
                {aDocVis ? item : '***'}
                </MenuItem>
            ))}
          </Select>
          <Box sx={style.docWhiteBackgroundBox}>
            <Stack direction="row" sx={style.docWhiteBackgroundStack}>
              <Typography sx={style.docWhiteBackgroundTypo}>Full Price: </Typography>
              <Typography sx={style.docWhiteBackgroundTypo2}>{aDocData.full}</Typography>
            </Stack>
            <Stack direction="row" sx={style.docWhiteBackgroundStack}>
              <Typography  sx={style.docWhiteBackgroundTypo}>Time (In Minutes):</Typography>
              <Typography  sx={style.docWhiteBackgroundTypo2}>{aDocData.time}</Typography>
            </Stack>
            <Stack direction="row" sx={style.docWhiteBackgroundStack}>
              <Typography  sx={style.docWhiteBackgroundTypo}>Price Per Hour: </Typography>
              <Typography  sx={style.docWhiteBackgroundTypo2}>{aDocData.costPH}</Typography>
            </Stack>
          </Box>
        </Box>
      </Grid>
     
      <Grid item xs={12} sm={12} md={3} sx={style.docGridItem}>
        <Typography sx={style.docTypo}>Doctor 2 {bDocVis ? <VisibilityIcon onClick={()=>setBDocVis(false)}/> : <VisibilityOffIcon  onClick={()=>setBDocVis(true)}/>}</Typography>
        <Box sx={style.docBox}>
        <Select  MenuProps={{ disableScrollLock: true }} value={bDoc} onChange={(e) => { outputData(setBDocData, bDocData, setBDoc, e.target.value) }}>
          <MenuItem sx={{width:'100px'}} value={bDoc}>Select Doctor</MenuItem>
          {docsList.map((item, idx) => (
            <MenuItem key={idx} value={item}>{bDocVis ? item : '***'}</MenuItem>
          ))}
        </Select>
          <Box sx={style.docWhiteBackgroundBox}>
            <Stack direction="row" sx={style.docWhiteBackgroundStack}>
              <Typography sx={style.docWhiteBackgroundTypo}>Full Price: </Typography>
              <Typography sx={style.docWhiteBackgroundTypo2}>{bDocData.full}</Typography>
            </Stack>
            <Stack direction="row" sx={style.docWhiteBackgroundStack}>
              <Typography  sx={style.docWhiteBackgroundTypo}>Time (In Minutes):</Typography>
              <Typography  sx={style.docWhiteBackgroundTypo2}>{bDocData.time}</Typography>
            </Stack>
            <Stack direction="row" sx={style.docWhiteBackgroundStack}>
              <Typography  sx={style.docWhiteBackgroundTypo}>Price Per Hour: </Typography>
              <Typography  sx={style.docWhiteBackgroundTypo2}>{bDocData.costPH}</Typography>
            </Stack>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} sx={style.docGridItem}>
        <Typography sx={style.docTypo}>Doctor 3 {cDocVis ? <VisibilityIcon onClick={()=>setCDocVis(false)}/> : <VisibilityOffIcon  onClick={()=>setCDocVis(true)}/>}</Typography>
        <Box sx={style.docBox}>
        
          <Select  MenuProps={{ disableScrollLock: true }} value={cDoc} onChange={(e) => { outputData(setCDocData, cDocData, setCDoc, e.target.value) }}>
            <MenuItem value={cDoc}>Select Doctor</MenuItem>
            {docsList.map((item, idx) => (
              <MenuItem key={idx} value={item}>{cDocVis ? item : '***'}</MenuItem>
            ))}
          </Select>
        
          <Box sx={style.docWhiteBackgroundBox}>
            <Stack direction="row" sx={style.docWhiteBackgroundStack}>
              <Typography sx={style.docWhiteBackgroundTypo}>Full Price: </Typography>
              <Typography sx={style.docWhiteBackgroundTypo2}>{cDocData.full}</Typography>
            </Stack>
            <Stack direction="row" sx={style.docWhiteBackgroundStack}>
              <Typography  sx={style.docWhiteBackgroundTypo}>Time (In Minutes):</Typography>
              <Typography  sx={style.docWhiteBackgroundTypo2}>{cDocData.time}</Typography>
            </Stack>
            <Stack direction="row" sx={style.docWhiteBackgroundStack}>
              <Typography  sx={style.docWhiteBackgroundTypo}>Price Per Hour: </Typography>
              <Typography  sx={style.docWhiteBackgroundTypo2}>{cDocData.costPH}</Typography>
            </Stack>
          </Box>
        </Box>
      </Grid> 
    </Grid>
  </Box>
 
</Box>

  )
}

export default Procedure