import { Button, Stack, Typography,Box, } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
// responsivness
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';


const AdminBar = ({user}) => {
  const navigate = useNavigate()

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'));
  // for hamburger menu
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (destination) => {
    sendTo(destination)
    if(destination === ''){
      localStorage.removeItem("user");
    }
    
    handleClose(); // Close menu after handling click
  };
  // end of hamburger menu

  function sendTo(link){
    if(link === ''){
      localStorage.removeItem("user");
    }
    navigate(`/${link}`)
  }

  const buttonStyle = {
    color: "black", borderRadius:'20px', marginLeft:'10px',
    width:"100px", height:'70%', padding:'10px 60px 10px 60px',
    
    transition: 'transform 0.3s, background-color 0.3s',
    '&:hover': { backgroundColor: 'lightgrey', transform: 'scale(1.05)' },
  }
  
 
   
  if (isLargeScreen) {
    return (
      <Stack direction={'row'} sx={{ boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)',display:'flex', justifyContent:'center', alignItems:'center', background:'#23408e', position:'absolute', top:'0', left:'0', right:'0',height:'100px'}} >
      <Stack sx={{marginLeft:'20%', marginRight:'auto'}}>
          <Typography variant='h4' sx={{color:'white', }}>Bundlr Admin</Typography>

      </Stack>
      <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={handleClick} sx={{marginRight:'50px', color:'white'}}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleMenuItemClick('costAnalysis')}>
          Price Calculator
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('procedure')}>
          Comparisons
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('doctor')}>
          Doctor
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('facility')}>
          Facility & Procedures
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('saved')}>
          Records
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('')}>
          Logout
        </MenuItem>
      </Menu>
    </>
      
    </Stack>
    );
  } else {
    return (
      <Stack direction={'row'} sx={{ boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)',display:'flex', justifyContent:'center', alignItems:'center', background:'#23408e', position:'absolute', top:'0', left:'0', right:'0',height:'100px'}} >
      <Stack sx={{marginLeft:'150px', marginRight:'auto'}}>
          <Typography variant='h4' sx={{color:'white', }}>Bundlr Admin</Typography>          
      </Stack>
      

      <Stack  direction={{ xs: 'column', md: 'row' }} sx={{  boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)', padding: { md:'2px', xxl:'8px'},marginRight:'100px',backgroundColor:'white', borderRadius:'20px',display:'flex', justifyContent:'center', alignItems:'center',}}>
        <Button onClick={()=>{sendTo('costAnalysis')}}  sx={buttonStyle}>Price Calculator</Button>
        <Button onClick={()=>{sendTo('procedure')}} sx={buttonStyle }>Comparisons</Button>
        <Button onClick={()=>{sendTo('doctor')}} sx={buttonStyle}>Doctor</Button>
        <Button onClick={()=>{sendTo('facility')}} sx={buttonStyle}>Facility & Procedures</Button>
        
        <Button onClick={()=>{sendTo('saved')}} sx={buttonStyle}>Records</Button>
        <Button onClick={()=>{sendTo('')}} sx={buttonStyle}>Logout</Button>
      </Stack>

    </Stack>

    );
  }
};
      
    
  


export default AdminBar