

import React, { useState, useEffect} from 'react'
// import { EmailSubmit } from '../../components'
import { Box, Stack, TextField, Typography, Button } from '@mui/material'
import { PDFViewer } from '@react-pdf/renderer'
import {CPDF, EmailSubmit} from '../component'
import styles from '../styles';
import { useNavigate } from 'react-router-dom'

const SubmitcPDF = ({pdfCompData, procedure,facilityData}) => {
  const style = styles;
  const navigate = useNavigate()

  useEffect(() => {
    if (facilityData.user === undefined) {
      navigate('/procedure')
    }
     
      
  }, [])
  const [emailField, setEmailField] = useState(facilityData.email)

  
  return (
    <Box sx={style.subCPDFMainBox}>
    <Typography variant='h2' sx={style.subCPDFSubmissionTypo}>
      Submission
    </Typography>
    <Typography sx={style.subCPDFDirectionTypo}>
      Fill out the Following Fields and then press Submit
    </Typography>
    <Stack sx={style.subCPDFMainStack}>
      <TextField sx={style.subCPDFTextField} label='Type Email' value={emailField} onChange={(e)=>{setEmailField(e.target.value)}}/>
      {/* pdfviewer doesnt like using sx i guesss */}
      <PDFViewer height={'600px'} width={'80%'}>
        <CPDF data={pdfCompData} procedure={procedure}/>
        </PDFViewer>
      </Stack>
    
   
      <Stack direction="row" sx={style.emailSubmitMainStack}>
        <EmailSubmit PDF={ <CPDF data={pdfCompData} procedure={procedure}/>}  title={'bundlr_Comparsions.pdf'} toEmail={emailField}/>
        <Button sx={style.emailSubmitButton2} onClick={() => navigate('/procedure')}>Cancel</Button>
      </Stack>
      
    </Box>
  )
}

export default SubmitcPDF