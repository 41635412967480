import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import AdminBar from '../component/AdminBar'
import { useNavigate } from 'react-router-dom'

const Admin = ({facilityData, setFacilityData}) => {

  const navigate = useNavigate()

  useEffect(() => {
    if (facilityData.user === undefined) {
      let user = window.localStorage.getItem('user');
      user = JSON.parse(user);
      console.log(user)
      if(user !== null){
        if(user.account_type !== 'admin'){
          navigate('/costAnalysis')
        }
        setFacilityData(user);
      }else{
        navigate('/')
      }
    }
  }, []);

  return (
    <Box>
      <AdminBar user={facilityData}/>
      <Box sx={{mt:'100px'}}>
        <Typography>Hello</Typography>
      </Box>
    </Box>
  )
}

export default Admin