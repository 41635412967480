
const styles  = {
   ///////////
   // COST ANALYSIS STYLING
   ///////////
    containerMain: {
        marginTop:'140px',display: 'flex',justifyContent: 'center',width: '100%',  backgroundColor: '#6a7b8c',  height: '100%'
    },
    leftColumnGridItem: {
      mt:{ xs:'80px', sm:'80px', md:'80px', lg:'0px'}, borderRight: { md: '0px solid black',lg:'1px solid black'},display:'flex', justifyContent:'center', alignItems:'center', flexDirection: 'column',
    },
   gridItemBoxColumn: {
    mb:'20px',width:'80%', backgroundColor: 'white', padding: '20px 20px 30px 20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' 
   },
   typographyItemBoxColumn: {
      alignItem:'center'
   },
   secondBoxColumn: {
     width: '85%', display: 'flex', flexDirection: 'column', alignItems: 'center', 
   },
   
   secondGridContainer: {
      width:'100%',display:'flex', justifyContent:'center', alignItems:'center'
   },
   // left column header dup was used
   leftColumnTextField: {
      width: '100%', 
      margin: '0 auto',
      backgroundColor: 'white',
      borderRadius: '14px',
      '& .MuiOutlinedInput-root': {
        '& fieldset': { border: '2px solid black', borderRadius: '10px' },
        '&.Mui-focused fieldset': { borderColor: '#23408e', borderWidth: '3px' }
      },

    },
   
  //reused toolTip
  //reused infoIcon
  leftColumnHeader: {
   width: '80%',
   minHeight: '35px',
   margin: '0 auto',
   borderRadius: '10px 10px 0px 0px',
   backgroundColor: 'white',
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center'
 },
   selectMenu2: {
      border: '2px solid black',backgroundColor: 'white', borderRadius:'14px', "& fieldset": { border: '2px solid black', borderRadius: '10px' },width: '100%','& .MuiOutlinedInput-notchedOutline': {border: 'none'}, '& .MuiMenuItem-root': { color: 'black' },'& .MuiOutlinedInput-root': {'&.Mui-focused fieldset': {borderColor: "transparent",borderWidth: '3px',}},
   },

   


   // left column header dup was used
   
  // left column header dup was used

   toolTip: {
      color: "white" 
   },
   infoIcon: {
      color: 'black',cursor: 'pointer' 
   },
   totalGridContainer: {
      mt:'20px', mb:'20px',width: '50%',ml:'auto', mr:'auto', display: 'flex',alignItems: 'center',justifyContent: 'center',
   },
   totalBox: {
      width:'50%',margin:'auto', backgroundColor: 'white', borderRadius:'10px', padding: '6px', display: 'flex', flexDirection: 'column', alignItems: 'center', 
   },
   totalTypo: {
      borderBottom: '2px solid black', paddingBottom: '5px', 
   },
   totalButton: {
     //cannot work as it depends on a function
   },
   middleColumnGridItem: {
      mt:{ xs:'80px', sm:'80px', md:'80px', lg:'0px'}, borderRight: { md: '0px solid black',lg:'1px solid black'}, display:'flex', justifyContent:'center', alignItems:'center', flexDirection: 'column',
   },
   middleColumnBox1: {
      mb:'20px',width:'80%', backgroundColor: 'white', padding: '20px 20px 30px 20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
   },
   middleColumnBox2: {
      width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', 
   },
   middleColumnContainer: {
      width:{xs: '100%',lg: '120%',xl:'100%'},display:'flex', justifyContent: 'center', alignItems: 'center' 
   },
   rightColumnContainer: {
      display:'flex', justifyContent: 'center', alignItems: 'center' 
   },
   rightColumnGridItem: {
       display:'flex', justifyContent: 'center', alignItems: 'center' , flexDirection: 'column',position:'relative'
   },
  
   firstRowMiddleItem: {
      
   },
   secondRowMiddleItem: {
     
   },
   middleColumnHeader: {
      // this is to be used for all 4 headers in middle column
      minHeight:'35px',borderRadius: '10px 10px 0px 0px ',mr:'auto', ml:'auto', backgroundColor: 'white',  width: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center' 
      
   },
   stackUnderAnesthesia: {
      backgroundColor: 'white', borderRadius:'10px', width: '100%', border: '2px solid black', 
   },
   anesthesiaBox: {
      height: '55px',borderRadius: '0px 0px 5px 5px', marginLeft:'auto', marginRight:'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' 
   },
   anesthesiaTextfield: {
      pl:'6px', "& .MuiInputBase-input": { padding: '0px !important' }, borderRight:'2px solid black', width: '30%', "& fieldset": { border: 'none' },
   },
   anesthesiaTypo2: {
      marginLeft:'10px', textAlign:"center"
   },
//  staffExpenseTypo to be used as middle column header
   expenseStack: {
      backgroundColor: 'white', borderRadius:'10px', width: '100%', border: '2px solid black',
    
   },
   expenseBox: {
      height: '55px', borderRadius: '5px', marginLeft:'auto', marginRight:'auto', display: 'flex', justifyContent: 'center', alignItems: 'center',
      
   },
   expenseTextfield: {
      pl:'6px',  "& .MuiInputBase-input": { padding: '0px !important' }, borderRight:'2px solid black', width: '30%', "& fieldset": { border: 'none' }
   },
   staffexpenseTypo2: {
      marginLeft:'10px'
   },
 
// facilityTypo to be used as middle column header
   
   facilltyStack: {
      backgroundColor: 'white', borderRadius:'10px',  width: '100%', border: '2px solid black', 
   },
   facilityBox: {
       height: '55px', borderRadius: '0px 0px 5px 5px', marginLeft:'auto', marginRight:'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'
   },
   facilityTextField: {
     pl:'6px',"& .MuiInputBase-input": { padding: '0px !important',borderRadius: '0px 0px 5px 5px' }, borderRight:'2px solid black',width: '30%', "& fieldset": { border: 'none' }, 
   },
   facilityTypo2: {
      marginLeft: '10px', textAlign:"center"
   },
//roomABTypo to be used as middle column header
   roomABStack: {
      backgroundColor: 'white', borderRadius:'10px',  width: '100%', border: '2px solid black',  
   },
   roomABBox: {
      height: '55px',marginLeft:'auto', marginRight:'auto', display: 'flex', justifyContent: 'center', alignItems: 'center'
   },
   roomTextField: {
        width:'100%', textAlign:'center', "& fieldset": { border: 'none' }, color:'black'
   },
   amountOfDayTextfield: {
      width: '45%',  ml:'5px', mr:'5px', height:'10%', '& .MuiOutlinedInput-root': {'& fieldset': {border: '2px solid black',borderRadius: '10px',},'&.Mui-focused fieldset': {borderColor: '#23408e',borderWidth: '3px' }},
   },
   rightColumnItem: {
      mt:{ xs:'80px', sm:'80px', md:'80px', lg:'0px'},  display:'flex', justifyContent:'center', alignItems:'center', flexDirection: 'column',
   },
  
   rightColumnBox: {
     mb:'20px',width:'80%',backgroundColor: 'white', padding: '20px 20px 30px 20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
   },
   rightColumnBox2: {
      width:'80%',borderRadius: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
   },
   
   rightColumnHeader: {
    width: '80%',
    minHeight: '35px',
    margin: '0 auto',
    borderRadius: '10px 10px 0px 0px',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
 
    rightColumnHeader2: {
      width: '50%',
      minHeight: '35px',
      margin: '0 auto',
      borderRadius: '10px 10px 0px 0px',
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
   rightColumnGridItem2: {
      width:'80%', display:'flex', justifyContent: 'center', alignItems: 'center' , flexDirection: 'column',
   },
   rightColumnTextField: {
      width: '100%', 
      margin: '0 auto',
      backgroundColor: 'white',
      borderRadius: '14px',
      '& .MuiOutlinedInput-root': {
        '& fieldset': { border: '2px solid black', borderRadius: '10px' },
        '&.Mui-focused fieldset': { borderColor: '#23408e', borderWidth: '3px' }
      }
    },

    rightColumnTextField2: {
      width: '60%',
      margin: '0 auto',
      backgroundColor: 'white',
      borderRadius: '14px',
      '& .MuiOutlinedInput-root': {
        '& fieldset': { border: '2px solid black', borderRadius: '10px' },
        '&.Mui-focused fieldset': { borderColor: '#23408e', borderWidth: '3px' }
      }
    },
  
   ///////////
   // COMPARISON STYLING
   ///////////
   
   mainBox: {
       minHeight: '100vh', display: 'flex', flexDirection: 'column', width:'100%'
   },
   secondaryBox: {
      flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop:'8rem',width:'100%'  
   },
   printPDFTypo: {
      pointerEvents: 'none',textAlign: 'center',  color: 'black', marginLeft: 'auto', marginRight: 'auto', borderRadius: '20px', backgroundColor: 'white', minHeight: '75px', width: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '10rem', left: '20px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.6)'
   },
   printPDFButton: {
      height:{xs:'55px',md:''},color: 'white',  borderRadius: '10px', backgroundColor: '#23408e', padding: '15px', width: '80%',transition: 'transform 0.3s, background-color 0.3s',
      '&:hover': {  transform: 'scale(1.05)',backgroundColor: '#1e3a7e' }, pointerEvents: 'auto',
   },
   procedureCodeTypo: {
      marginLeft: 'auto', marginRight: 'auto',  borderRadius: '10px 10px 0px 0px', backgroundColor: 'white', minHeight: '35px', width: { xs:'33%', sm:'23%', md: "16%",lg:'13%',xxl:'12%'}, display: 'flex', justifyContent: 'center', alignItems: 'center', 
   },
   boxAfterProcedureCodeText: {
      backgroundColor:'#23408e', padding:'20px', height:'50px', borderRadius:'30px', position:'relative'
   },
   procedureCodeTextField: {
      backgroundColor: 'white', borderRadius:'14px',
     '& .MuiOutlinedInput-root': {'& fieldset': {border: '2px solid black',borderRadius: '10px',},'&.Mui-focused fieldset': {borderColor: '#23408e',borderWidth: '3px' }},width: '100%',"& .MuiInputBase-input": { textAlign: 'center' }, marginBottom:'100px'
   },
  // reused tooltip
  // reused InfoIcon
  profitMarginTypo: {
    textAlign: 'center', color: 'black', marginLeft: 'auto', marginRight: 'auto', borderRadius: '20px', backgroundColor: 'white', minHeight: '75px', width: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '10rem', right: '20px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.6)'
  },
  spacerBox: {
   marginTop:'2rem'
  },

  blueBackgroundContainer: {
   borderTop:'5px solid black',backgroundColor:'#23408e',justifyContent: 'center',display: 'flex', alignItems: 'center', padding: '20px 0',  flex: '1 1 auto',
  },

  docGridItem: {
    mr:{ xs:'0px',md:'25px',}, ml:{ xs:'0px',md:'25px',}, border:{xs:'0px solid black',lg:'5px solid black'}, borderRadius:'20px', padding:'20px',
        height:'100%',backgroundColor:'#23408e', width:'30%', display: 'flex',alignItems: 'center', justifyContent: 'center',flexDirection: 'column',
  },
  docTypo: {
    mt:{ xs: '10px', xxl:'0px'}, fontSize: { xs:'26px', xxl:'46px'}, backgroundColor: 'white', padding: '10px', borderRadius: '5px'
  },
  docBox: {
   backgroundColor:'white', padding:'8%', borderRadius:'10px',marginTop:'20px',display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column',
  },

  docWhiteBackgroundBox: {
   display: 'flex', alignItems: 'left', justifyContent: 'center',flexDirection: 'column',width:'100%'
  },
  docWhiteBackgroundStack: {
   margin: '10px', 
   alignItems: 'center', 
   padding: '10px', 
   width: '100%',
 },
 docWhiteBackgroundTypo: {
   minWidth: '150px', 
   textAlign: 'left', 
 },
 docWhiteBackgroundTypo2: {
   marginLeft: '20px',
   
 },
 

   ///////////
   // DOCTOR STYLING
   ///////////

  doctorMainBox: {
   display: 'flex', flexDirection: 'column', height: '100vh'
  },
  mainStack: {
   flex: 1, marginTop: '100px'
  },
  boxAfterNavbar: {
   background: "white", width: { xs: '350px', md: '430px', xxl: '400px' }, overflow: 'auto',display: 'flex', height: '100%'  
  },
  docBarStack: {
   width:'100%'
  },
  docbarTypo: {
   display:'flex', alignItems:'center', justifyContent:'center', width:'100%',height:'80px', backgroundColor:'lightgrey'
  },
  docbarCard: {
   display:'flex', alignItem:'center',borderRadius: '5px', backgroundColor: '#6a7b8c', width: '100%', height: '100px', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', transition: 'transform 0.2s', '&:hover': { transform: 'scale(1.05)' } 
  },
  cardActionArea: {
   padding:'20px 10px 20px 10px'
  },
  docNameTypo: {
   textAlign:'center', color:'white'
  },
  addDocCard: {
   backgroundColor:'lightgrey',marginTop: 'auto', width: '100%', height: '8%', marginLeft: 'auto', marginRight: 'auto',
  },
  addDocCardActionArea: {
   width: "100%", height: "100%"
  },
  addDocTypo: {
   display:'flex', justifyContent:'center', alignItem:'center', textAlign:'center' 
  },
  // reused tooltip
  secondaryInfoIcon: {
   color: 'white',cursor: 'pointer',marginLeft:'10px'
  },
  thirdInfoIcon: {
   color: 'black',cursor: 'pointer',marginLeft:'10px', mt:{xs:'0px',lg:'10px'}
  },
  selectADocBox: {
   width: '80%', marginLeft: '0px', backgroundColor:'#6a7b8c' 
  },
  selectADocBox2: {
   marginTop: "50px" 
  },
  selectADocBox3: {
   borderRadius:'20px',backgroundColor: 'white', width: '50%', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto', 
  },
  selectADocBoxTypo: {
   textAlign: 'center',
   fontSize: '1.5rem', // Default size
   '@media (min-width: 768px)': {
     fontSize: '2rem', // Size for medium and up
   },
   '@media (min-width: 1200px)': {
     fontSize: '2.5rem', // Size for large and up
   },
  },

  // reused tooltip
  doctorInfoIcon: {
   color: 'black',cursor: 'pointer', ml:'15px'
  },
  selectADoclistBox: {
   marginTop: '40px', backgroundColor: 'white',  flexDirection: 'column', border: '.1px solid lightgray', borderRadius: '10px', 
   padding: '20px',  width: '80%', display: 'flex',  justifyContent: 'space-around', 
   alignItems: 'center',  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', marginLeft:'auto', marginRight:'auto' , marginBottom:'50px'
  },
  selectADoclistStack: {
    width: '100%', justifyContent: 'space-between', marginBottom: '10px', borderBottom:' 1px solid lightgrey'
  },
  selectADoclistHeaderTypo: {
   flex: '1 1 20%', paddingRight: '10px',
   fontSize: '.95rem', // Default size
   '@media (min-width: 768px)': {
     fontSize: '1.15rem', // Size for medium and up
   },
   '@media (min-width: 1200px)': {
     fontSize: '1.55rem', // Size for large and up
   },

  },

  selectADoclistMappingBox: {
    width: '100%' 
  },
  selectADoclistPaginateBox: {
  // backgroundColor: idx % 2 === 0 ? 'lightgrey' : 'white', borderRadius:'5px',padding:'3px',marginTop:'5px',display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center',
  },
  // reused selectADoclistHeaderTypo
  pagination: {
   color: 'white',ml: 'auto',mr: 'auto',mt: '20px','& .MuiPaginationItem-root': {color: 'black',},'& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: 'lightgrey', color: 'black'},'& .MuiPaginationItem-page:hover': {backgroundColor: 'grey', color: 'black', }
  },

  // reused tooltip
  // reused secondaryInfoBox

  docProcedureButtonBox: {
   flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' 
  },
  docProcedureAddButton: {
   marginTop:'0px',marginLeft: 'auto', marginRight: 'auto', mb:'40px', backgroundColor:'#1e3a7e', color:'white',minWidth:'12%',height:'50px',
   transition: 'transform 0.3s, background-color 0.3s',
   '&:hover': { backgroundColor: '#1e3a7e', transform: 'scale(1.05)' },
  },
  docRemoveButton: {
   opacity:'50%',backgroundColor:'#1e3a7e', color:'white',width:'9%',height:'50px',
   transition: 'transform 0.3s, background-color 0.3s', position:'fixed', right:'20px', bottom:'20px',
   '&:hover': {opacity:'100%', backgroundColor: '#1e3a7e', transform: 'scale(1.05)' },
  },
  docContainerProcAdd: {
   justifyContent: 'space-around', width:'80%', display:'flex',  flexDirection: 'center', justifyContent: 'center', mr:'auto', ml:'auto'
  },
  newProcTypo: {
   marginLeft:'auto', marginRight:'auto', borderRadius: '10px 10px 0px 0px', backgroundColor: 'white', minHeight: '35px', width: '75%', display: 'flex', justifyContent: 'center', alignItems: 'center' 
  },
  docAddProcTextField: {
   backgroundColor: 'white',borderRadius: ' 14px',"& fieldset": {border: '2px solid black',borderRadius: ' 10px', mr:'auto', ml:'auto', 
   },width: '100%',"& .MuiInputBase-input": {borderRadius: ' 10px',textAlign: 'center',},'& .MuiOutlinedInput-root': {'& fieldset': {border: '2px solid black',borderRadius: '10px',},'&.Mui-focused fieldset': {borderColor: '#23408e',borderWidth: '3px' }},
  },
  docAddProcComfirmBox: {
    mb:'20px',mt:'20px',display: 'flex', flexDirection: 'center', justifyContent: 'center'
  },
  docAddProcConfirmButton: {
   backgroundColor:'#1e3a7e', color:'white', marginRight:'20px', width:'100px', height:'50px',
   transition: 'transform 0.3s, background-color 0.3s',
   '&:hover': { backgroundColor: '#1e3a7e', transform: 'scale(1.05)' },
  },
 addADocBox: {
    display:'flex', flexDirection: 'column', marginLeft:'auto',marginRight:'auto', alignItems:'center', justifyContent:'center', height:'100%', width:'80%'
 },
 addADocBox2: {
   height:'100px',width:'30%', backgroundColor: 'white', padding: '20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
 },
 addDocTypo2: {
   display:'flex', justifyContent:'center', alignItem:'center', textAlign:'center',
   

 },
 // reused Tooltip
 // reused InfoIcon

 // reused newProcTypo
 // reused docAddProcTextField
addADocProcAddContainer: {
   display:'flex', alignItems:'center', justifyContent:'center'
},
addADocInitalProcAddGridItem:{
    display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px',position:'relative'
},
addADocInitalProcAddTypo: {
   width: '45%', borderRadius: '10px 10px 0 0', backgroundColor: 'white', height: '35px', display: 'flex', justifyContent: 'center', alignItems: 'center', 
},
addADocInitalProcAddTextField: {
   backgroundColor: 'white', borderRadius: '14px', width: '70%', '& .MuiOutlinedInput-root': {'& fieldset': {border: '2px solid black',borderRadius: '10px',},'&.Mui-focused fieldset': {borderColor: '#23408e',borderWidth: '3px' }},
},
addADocComfirmStack: {
   width:'50%', alignItems:'center', display:'flex', justifyContent:'center'
},
// reused docAddProcConfirmButton

///////////
// DOCTOR STYLING
///////////

hospitalMainStack: {
   height:'100vh'
},
hospitalMainBox: {
    width:'40vw', display:'flex',mt:'40px'
},
hospitalMainBox2: {
   marginLeft:'auto', marginRight:'auto', marginTop:'0rem'
},
expensesTypoBox: {
   marginTop:'100px',height:'100px',width:'20vw', backgroundColor: 'white', padding: '20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',marginLeft:'auto', marginRight:'auto',
},
// if there is 1 more then idk
firstHospitalGridContainer: {
   marginTop:'40px'
},
anesthesiaHourTypo: {
   ml:'auto', mr:'auto', mt: '20px', borderRadius: '10px 10px 0px 0px', backgroundColor: 'white', minHeight: '35px', width: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center' 
},
hospitalHourlyTextField: {
   backgroundColor: 'white', borderRadius:'14px', width:'100%', '& .MuiOutlinedInput-root': {'& fieldset': {border: '2px solid black',borderRadius: '10px',},'&.Mui-focused fieldset': {borderColor: '#23408e',borderWidth: '3px' }},
},
staffExpenseHourTypo: {
   marginLeft:'auto', marginRight:'auto', marginTop: '20px', borderRadius: '10px 10px 0px 0px', backgroundColor: 'white', minHeight: '35px', width: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center' 
},

facilityCostHourTypo: {
   marginLeft:'auto', marginRight:'auto', marginTop: '20px', borderRadius: '10px 10px 0px 0px', backgroundColor: 'white', minHeight: '35px', width: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center'
},
// reused hospitalHourlyTextField for the other 3 textfields
RaBCTypo: {
    textAlign:'center', marginLeft:'auto', marginRight:'auto', marginTop: '20px', borderRadius: '10px 10px 0px 0px', backgroundColor: 'white', minHeight: '35px', width: '85%', display: 'flex', justifyContent: 'center', alignItems: 'center'
},
hospitalProcComfirmBox: {
   display:'flex'
},
hospitalProcComfirmButton: {
   backgroundColor:'#23408e', marginLeft:'auto', marginRight:'auto',marginTop:'20px',
   transition: 'transform 0.3s, background-color 0.3s', width:'50%',height:'50px', mb:'20px',
   '&:hover': {  transform: 'scale(1.05)',backgroundColor: '#1e3a7e' },
},
hospitalProcButtonUpdateButton: {
   marginLeft:'auto', marginRight:'auto',marginTop:'20px',
   backgroundColor:'#23408e', color:'white', width:'50%',height:'50px',
   transition: 'transform 0.3s, background-color 0.3s', mb:'20px',
   '&:hover': {  transform: 'scale(1.05)',backgroundColor: '#1e3a7e' },
},

hourlyBox: {
   marginTop:'145px',height: '80vh', borderRight: '1px solid black', 
},
hourlyBox2: {
   width:"60vw",display:'flex'
},
hourlyBox3: {
   marginLeft:'auto', marginRight:'auto', marginTop:'0rem'
},
hourlyBox4: {
   marginLeft:'auto', marginRight:'auto', marginTop:'140px',height:'100px',width:'20vw', backgroundColor: 'white', padding: '20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' 
},
hospitalArrayBox: {
   marginTop: '40px', backgroundColor: 'white', flexDirection: 'column', border: '.1px solid lightgray', borderRadius: '10px', padding: '20px', 
   width: {xs:'300px', md:'500px',lg:'600px', xl:'700px',xxl:'800px'}, display: 'flex', justifyContent: 'space-around', alignItems: 'center', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', marginLeft:'auto', marginRight:'auto',
},

// pagination styling goes here (putting it aside for now)

hospitalArrayHeaderStack: {
   mt:'20px', width: '100%', justifyContent: 'space-between', marginBottom: '10px', borderBottom:' 1px solid lightgrey' 
},
hospitalEditTextField: {
   width:'30%', mr:'14px', backgroundColor:'white',borderRadius:'14px', '& .MuiOutlinedInput-root': {'& fieldset': {border: '2px solid black',borderRadius: '10px',},'&.Mui-focused fieldset': {borderColor: '#23408e',borderWidth: '3px' }},   
},
hospitalArrayTypo: {
   flex: '1 1 0',
   fontSize: '.95rem', // Default size
   '@media (min-width: 768px)': {
     fontSize: '.85rem', // Size for medium and up
   },
   '@media (min-width: 1200px)': {
     fontSize: '1.15rem', // Size for large and up
   },
},
hospitalMappingBox: {
   width: '100%' 
},
hospitalProcListSaveButton: {
   marginLeft:'auto', marginRight:'auto',marginTop:'20px', display:'flex',
   backgroundColor:'#23408e', color:'white', width:'25%',height:'50px',
   transition: 'transform 0.3s, background-color 0.3s',
   '&:hover': {  transform: 'scale(1.05)',backgroundColor: '#1e3a7e' },
},
// reused hospitalArrayTypo a lot
hospitalProcListAddBox: {
    alignItems:'center', display:'flex', justifyContent:'center'
},
hospitalAddorCancelStack: {
   mr:'auto', ml:'auto', mb:'20px', width:'70%',alignItems:'center', display:'flex', justifyContent:'center'
},
hospitalProcListAddButton: {
   marginTop:'20px', margin:'10px', width:'100%',
   backgroundColor:'#23408e', color:'white', width:'25%',height:'50px',
   transition: 'transform 0.3s, background-color 0.3s',
   '&:hover': {  transform: 'scale(1.05)',backgroundColor: '#1e3a7e' },
},
hospitalProcAddFieldsStack: {
   display:'flex',marginLeft:'auto', marginRight:'auto', marginTop:"20px"
},
hospitalProcAddContainer: {
   justifyContent:'space-around'
},
hospitalProcAddGridItem: {
   marginRight:{xs:'0px',md:'20px'}, mt:'7px'
},
hospitalProcAddGridItem2: {
   mt:'7px'
},
hospitalProcAddTypo: {
    marginLeft:'auto', marginRight:'auto', borderRadius: '100px 100px 0px 0px', backgroundColor: 'white', minHeight: '35px', width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center'
},
hospitalProcAddTextField: {
   width:'100%', backgroundColor:'white',borderRadius:'14px', '& .MuiOutlinedInput-root': {'& fieldset': {border: '2px solid black',borderRadius: '10px',},'&.Mui-focused fieldset': {borderColor: '#23408e',borderWidth: '3px' }},   
},
// resued hospitalProcListAddButton

///////////
// SAVED STYLING
///////////

savedMainBox: {
   backgroundColor:'#6a7b8c', height:'61.7vh'
},
savedMainBox2: {
   marginTop:"130px", display:'flex', flexDirection: 'column',
},
bundlesHeaderBox: {
   marginLeft:'auto', marginRight:'auto', marginTop:'20px',height:'50px',width:'20vw', backgroundColor: 'white', padding: '20px', borderRadius: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
},
toolTipBundles: {
   color:'white', ml:'10px'
},
infoIconsBundles: {
    ml:'10px', mt:'10px'
},
searchNFilterStack: {
   ml:'auto', mr:'auto', mt:'20px',width:{xs:"100%",md:'80%'},display:'flex', justifyContent:'center', alignItems:'center', 
},
savedFormControl: {
   width:'35%'
},
savedFormControlSelect: {
   flex: 1,  height: '100%',borderRadius: '20px 0px 0px 20px',color: 'black','& .MuiSelect-icon': { color: 'black' },'& .MuiMenuItem-root': { color: 'black' },'& .MuiOutlinedInput-notchedOutline': {border: 'none', },'& .MuiOutlinedInput-root': {'&.Mui-focused fieldset': {borderColor: "transparent",borderWidth: '3px',}}},
 
serachFieldBox: {
   display:'flex', justifyContent:'center', alignItems:'center', width:'60%', borderRadius:'20px', border:'2px solid black', backgroundColor:'white'
},
serachFieldTextfield: {
   flex: 1, height: '100%','& .MuiOutlinedInput-root': {'& fieldset': {borderRadius: '0px 20px 20px 0px',},'&.Mui-focused fieldset': {borderColor: "transparent",borderWidth: '3px',},'& .MuiOutlinedInput-notchedOutline': {border: 'none', },},width: '100%',},
savedTooltipButton: {
   pl:'10px',pr:'10px'
},
savedListHeader: {
   marginTop: '20px', backgroundColor: 'white',flexDirection: 'column', border: '.1px solid lightgray', borderRadius: '10px', padding: '20px', width: { xs:'80%', md:'80%',lg:'60%', xl:'60%',xxl:'60%'},  display: 'flex', justifyContent: 'space-around', alignItems: 'center', boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', marginLeft:'auto', marginRight:'auto',
},
savedListHeaderStack: {
   ml:'auto', mr:'auto', width: '100%', justifyContent: 'space-between', marginBottom: '10px', borderBottom:' 1px solid lightgrey' 
},
savedListTypo: {
   flex: '1 1 0',textAlign:'center'
},
savedListBodyBox: {
   width: '100%'
},
// styling has function so skip
savedPagination: {
   color: 'white',ml: 'auto',mr: 'auto', mt: '20px','& .MuiPaginationItem-root': {
   color: 'white',},'& .MuiPaginationItem-page.Mui-selected': {backgroundColor: 'white', color: 'black', },'& .MuiPaginationItem-page:hover': {backgroundColor: 'white', color: 'black',}
},

///////////
// SIGN IN STYLING
///////////

signInMainStack: {
    height:'100vh', width:'100%', display:'flex', justifyContent:'center', alignItems:'center'
},

firstSignInBox: {
   backgroundImage: `url(${process.env.PUBLIC_URL}/BackgroundAccent.png)`,backgroundRepeat: "no-repeat", backgroundSize: '98% 85%',   backgroundPosition: 'center',
   transform: {md:'rotate(-15deg)',lg:'rotate(-35deg)'}, margin: { md:'40px 20px 20px 100px',lg:'80px 60px 20px 120px', xxl:'80px 30px 20px 20px'},  padding: {md:'65px',lg:'20px', xxl:'0px'},  
   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    border: '2px solid black',
    borderRadius: '20px',
    backgroundColor: "#1e3a7e",
    height: { md:'300px' ,lg:'350px', xxl:'450px'},
    width: { md:'300px', lg:'350px', xxl:'450px'},
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
},
secondSignInBox: {
   zIndex: 10, transition: 'transform 1.2s', '&:hover': { transform: 'scale(1.02)' }, margin: '-50px -160px 20px -160px', padding:'30px', 
   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
   border: '2px solid black',
   borderRadius: '20px',
   backgroundColor: "#1e3a7e",
   height: '400px',
   width: {md:'600px',lg:'400px'},
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'center',
   alignItems: 'center',
},
// to hide microsoft edge password thingy
scrollVis: {
   "input::-ms-reveal, input::-ms-clear": {
     display: "none"
   }
 },
 signInTitle: {
   textAlign:'center', color:'white'
 },
 signInSubTitle: {
   marginTop:'50px', color:'white', textAlign:'center'
 },
 signInMainStack2: {
   width:'80%', margin: '25px auto 0 auto',display:'flex', marginLeft:'auto',marginRight:'auto' 
 },
 signInMainTextField: {
   backgroundColor:'#1e3a7e', borderRadius: '20px', width:'80%', display:'flex', margin: '25px auto 0', '& .MuiOutlinedInput-root': {borderRadius: '20px', backgroundColor:'#1e3a7e' , '& fieldset': {borderColor: 'black', borderWidth: '1px', }, '&:hover fieldset, &.Mui-focused fieldset': {borderColor: 'black',},}, '& .MuiInputBase-input': {color: 'white'}, '& .MuiInputLabel-root': {color: 'white'}
 },
thirdInfoInfo: {
   color: 'white',cursor: 'pointer'
},
signInSecondaryTextField: {
   borderRadius: '20px', width:'80%', display:'flex', margin: '25px auto 0', '& .MuiOutlinedInput-root': {borderRadius: '20px', '& fieldset': {borderColor: 'black', borderWidth: '1px'}, '&:hover fieldset, &.Mui-focused fieldset': {borderColor: 'black'},}, '& .MuiInputBase-input': {color: 'white'}, '& .MuiInputLabel-root': {color: 'white'}
},
signInTypo: {
   color:'red', textAlign:'center'
},
signInTypoButton: {
   height:"50px", width:'100%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', border:'1px solid black',display:'flex', marginLeft:'auto',marginRight:'auto',marginTop:'20px', color: 'white',  borderRadius: '10px', backgroundColor: '#23408e', padding: '15px', transition: 'transform 0.3s, background-color 0.3s',
   '&:hover': {  transform: 'scale(1.05)',backgroundColor: '#1e3a7e',boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', },
},
signInLoadingTypo: {
   color:'white',marginTop:'50px'
},
signInThirdBox: {
   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',border: '2px solid black',borderRadius: '20px',backgroundColor: "#1e3a7e",height: { md:'300px' ,lg:'350px', xxl:'450px'},width: { md:'300px', lg:'350px', xxl:'450px'},display: 'flex',flexDirection: 'column',justifyContent: 'center',alignItems: 'center', backgroundImageTransform: 'scaleX(-1)',
    transform: {md:'rotate(15deg)',lg:'rotate(35deg)'},margin: { md:'40px 120px 20px 20px',lg:'80px 120px 20px 60px', xxl:'80px 120px 20px 20px'},backgroundRepeat: "no-repeat", backgroundSize: '98% 85%',   backgroundPosition: 'center',padding: {md:'65px',lg:'20px', xxl:'0px'},
    position: 'relative',
    overflow: 'hidden',
  
    // Adjust box after elements 
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${process.env.PUBLIC_URL}/BackgroundAccent.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '98% 85%',
      backgroundPosition: 'center',
      transform: 'scaleX(-1)', // Flip Image
      zIndex: -1, 
    }
},

///////////
// SUBMITCPDF STYLING
///////////
subCPDFMainBox: {
   textAlign:'center', backgroundColor: '#6a7b8c'
},
subCPDFSubmissionTypo: {
   color:'white'
},
subCPDFDirectionTypo: {
   marginBottom:'20px',fontStyle:'italic', textAlign:'center', fontSize:'20px', color:'white'
},
subCPDFMainStack: {
   display:'flex', justifyContent:'center', alignItems:'center'
},
subCPDFTextField: {
   backgroundColor: 'white', borderRadius:'14px', "& fieldset": { border: '2px solid black', borderRadius: '10px' },width: '30%' 
},


///////////
// CPDF STYLING
///////////

CPDFpage: {
   padding: 20,
 },
 CPDFheader1: {
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   backgroundColor: '#23408e',
   marginBottom: 20,
   height: 100,
 },
 CPDFheaderText1: {
   fontSize: 26,
   fontWeight: 'bold',
   color: 'white',
 },
 CPDFheader: {
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   backgroundColor: '#6a7b8c',
   borderRadius: 20,
   marginBottom: 20,
   height: 50,
   width: '40%',
   alignSelf: 'center',
 },
 CPDFheaderText: {
   fontSize: 16,
   fontWeight: 'bold',
   color: 'white',
 },
 CPDFtableHeader: {
   flexDirection: 'row',
   justifyContent: 'space-between',
   borderBottom: '2px solid black',
   paddingBottom: 10,
   marginBottom: 10,
 },
 CPDFtableHeaderText: {
   fontSize: 12,
   flex: 1,
   textAlign: 'center',
 },
 CPDFtableRow: {
   flexDirection: 'row',
   justifyContent: 'space-between',
   alignItems: 'center',
   marginBottom: 10,
 },
 CPDFtableCell: {
   flex: 1,
   textAlign: 'center',
   borderBottom: '2px dashed black',
   paddingVertical: 5,
 },
 CPDFtableCellText: {
   fontSize: 18,
 },


///////////
// DOC BAR STYLING
///////////

 DocBarMainStack: {
   background:"grey",position:"absolute", top:'100px',left:0,right:0, height:"80px", overflow:'auto'
 },
 DocBarCard: {
   border:'1px solid black', minWidth:'200px', height:'90%'
 },
 DocBarCardActionArea: {
   width:"100%", height:"100%"
 },
 DocBarCardTypo: {
   textAlign:'center'
 },
 DocBarCard2: {
   border:'1px solid black', width:'200px', height:'90%'
 },
 DocBarCardActionArea2: {
   width:"100%", height:"100%"
 },

   ///////////
   // EMAIL SUBMIT STYLING
   ///////////

 emailSubmitButton: {
   mr:'10px', fontSize:'24px',color: 'white',  borderRadius: '10px', backgroundColor: '#23408e', padding: '15px', width: '200px',transition: 'transform 0.3s, background-color 0.3s',
   '&:hover': {  transform: 'scale(1.05)',backgroundColor: '#1e3a7e' }, pointerEvents: 'auto',
 },
 emailSubmitTypo: {
   textDecoration:'none', color:'white', 
 },
emailSubmitMainStack : {
   m:'10px',display:'flex',  justifyContent:'center', alignItems:'center'
},
emailSubmitButton2: {
   fontSize:'24px',color: 'white',  borderRadius: '10px', backgroundColor: '#23408e', padding: '15px', width: '200px',transition: 'transform 0.3s, background-color 0.3s',
   '&:hover': {  transform: 'scale(1.05)',backgroundColor: '#1e3a7e' }, pointerEvents: 'auto',
 },



   ///////////
   // NAV BAR STYLING
   ///////////
navBarLargeScreenStack: {
    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)',display:'flex', justifyContent:'center', alignItems:'center', background:'#23408e', position:'absolute', top:'0', left:'0', right:'0',height:'100px'
},
navBarTitleStack: {
   marginLeft:'20%', marginRight:'auto'
},
navBarTitleTypo: {
   color:'white'
}, 
navBarIconButton: {
   marginRight:'50px', color:'white'
},
navBarHamMenuStack: {
    boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)',display:'flex', justifyContent:'center', alignItems:'center', background:'#23408e', position:'absolute', top:'0', left:'0', right:'0',height:'100px'
},
navBarHamTitleStack: {
   marginLeft:'150px', marginRight:'auto'
},
navBarHamStack: {
   boxShadow: '0 8px 12px rgba(0, 0, 0, 0.3)', padding: { md:'2px', xxl:'8px'},marginRight:'100px',backgroundColor:'white', borderRadius:'20px',display:'flex', justifyContent:'center', alignItems:'center',
}


}

export default styles;